import { AWARD_CODES, TTCI_AWARD_CODES } from "../../../../common/enums/award-codes";

export type annualBonusBreakdownMapItem = {
  awardCode: number;
  name: string;
  ns: string;
};

export const annualBonusBreakdownMap: annualBonusBreakdownMapItem[] = [
  {
    awardCode: AWARD_CODES.ANNUAL_PGG,
    name: "personalGrowth",
    ns: "bonusBreakdown",
  },
  {
    awardCode: AWARD_CODES.PGG_GCR,
    name: "personalGrowthGcr",
    ns: "bonusBreakdown",
  },
  {
    awardCode: AWARD_CODES.FGG,
    name: "frontlineGrowth",
    ns: "bonusBreakdown",
  },
  {
    awardCode: AWARD_CODES.TWO_TIME_CASH_INCENTIVE,
    name: "twoTimeCashIncentive",
    ns: "bonusBreakdown",
  },
  {
    awardCode: AWARD_CODES.QCG,
    name: "qcGrowthIncentive",
    ns: "bonusBreakdown",
  },
  {
    awardCode: AWARD_CODES.FAA,
    name: "faa",
    ns: "bonusBreakdown",
  },
  {
    awardCode: AWARD_CODES.EMERALD,
    name: "emerald",
    ns: "bonusBreakdown",
  },
  {
    awardCode: AWARD_CODES.DIAMOND,
    name: "diamond",
    ns: "bonusBreakdown",
  },
  {
    awardCode: AWARD_CODES.DIAMOND_PLUS,
    name: "diamondPlus",
    ns: "bonusBreakdown",
  },
  {
    awardCode: TTCI_AWARD_CODES.SILVER,
    name: "awardLabels.silver",
    ns: "common",
  },
  {
    awardCode: TTCI_AWARD_CODES.GOLD,
    name: "awardLabels.gold",
    ns: "common",
  },
  {
    awardCode: TTCI_AWARD_CODES.PLATINUM,
    name: "awardLabels.platinum",
    ns: "common",
  },
  {
    awardCode: TTCI_AWARD_CODES.FOUNDERS_PLATINUM,
    name: "awardLabels.foundersPlatinum",
    ns: "common",
  },
  {
    awardCode: TTCI_AWARD_CODES.SAPPHIRE,
    name: "awardLabels.sapphire",
    ns: "common",
  },
  {
    awardCode: TTCI_AWARD_CODES.FOUNDERS_SAPPHIRE,
    name: "awardLabels.foundersSapphire",
    ns: "common",
  },
  {
    awardCode: TTCI_AWARD_CODES.IN_MARKET_EMERALD,
    name: "awardLabels.inMarketEmerald",
    ns: "common",
  },
  {
    awardCode: TTCI_AWARD_CODES.IN_MARKET_FOUNDERS_EMERALD,
    name: "awardLabels.inMarketFoundersEmerald",
    ns: "common",
  },
  {
    awardCode: TTCI_AWARD_CODES.IN_MARKET_DIAMOND,
    name: "awardLabels.inMarketDiamond",
    ns: "common",
  },
  {
    awardCode: TTCI_AWARD_CODES.IN_MARKET_FOUNDERS_DIAMOND,
    name: "awardLabels.inMarketFoundersDiamond",
    ns: "common",
  },
  {
    awardCode: TTCI_AWARD_CODES.A70_IN_MARKET_EMERALD,
    name: "awardLabels.a70InMarketEmerald",
    ns: "common",
  },
  {
    awardCode: TTCI_AWARD_CODES.A70_IN_MARKET_FOUNDERS_EMERALD,
    name: "awardLabels.a70InMarketFoundersEmerald",
    ns: "common",
  },
  {
    awardCode: TTCI_AWARD_CODES.A70_IN_MARKET_DIAMOND,
    name: "awardLabels.a70InMarketDiamond",
    ns: "common",
  },
  {
    awardCode: TTCI_AWARD_CODES.A70_IN_MARKET_FOUNDERS_DIAMOND,
    name: "awardLabels.a70InMarketFoundersDiamond",
    ns: "common",
  },
];
