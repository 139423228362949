import { createApi } from "@reduxjs/toolkit/query/react";
import { dynamicBaseQuery } from "..";
import { TTCIOverviewResponse } from "./TwoTimeCashIncentives.types";

type TTCIRequestParams = {
  abo: string;
  aff: string;
  locale: string;
  period: string;
  isoCountryCode: string;
  isoCurrencyCode: string;
};

export const ttciAPI = createApi({
  reducerPath: "ttciAPI",
  baseQuery: dynamicBaseQuery,
  endpoints: (builder) => ({
    getTtciOverview: builder.query<TTCIOverviewResponse, TTCIRequestParams>({
      query: (params) => {
        const { abo, aff, locale, period, isoCountryCode, isoCurrencyCode } = params;
        const queryParams = new URLSearchParams({
          Abo: abo,
          Aff: aff,
          Locale: locale,
          Period: period,
          IsoCountryCode: isoCountryCode,
          IsoCurrencyCode: isoCurrencyCode,
        });

        return `/incentives-v2/api/ttci/overview?${queryParams}`;
      },
      extraOptions: { urlBase: "apiBaseUrl" },
    }),
  }),
});

export const { useGetTtciOverviewQuery } = ttciAPI;
