import { Navigate, Route, Routes } from "react-router-dom";
import { lazy } from "react";
import App from "../App";
import PerformanceDashboard from "../components/PerformanceDashboard/PerformanceDashboard";
import EarningsCalculator from "../components/EarningsCalculator/EarningsCalulator";
import { ROUTE_PATHS } from "../common/enums/routing-enums";
import CSI from "../components/CustomerSalesIncentive/CustomerSalesIncentive";
import HAT from "../components/HAT/HAT";
import FAA from "../components/FAA/FAA";
import ProvideUsFeedback from "../components/Resources/ProvideUsFeedback/ProvideUsFeedback";
import LogOut from "../components/LogOut/LogOut";
import LOS from "../components/RwLos/RwLos";
import EdLos from "../components/EdLos/EdLos";
import Watchlist from "../components/Watchlist/Watchlist";
import { HomePage } from "../components/HomePage/HomePage";
import { ErrorPage } from "../components/ErrorPage/ErrorPage";
import { CustomersRoutes } from "./SubRoutes/Customers";
import { MBIRoutes } from "./SubRoutes/MBI";
import { ActionReportsRoutes } from "./ActionReportsRoutes";
import { ErrorRoutes } from "./ErrorRoutes";
import { AuthenticationRoutes } from "./AuthenticationRoutes";
import { AcademyRoute } from "./AcademyRouting";

const PathToBronze = lazy(() => import("../components/BronzeIncentives/PathToBronze/PathToBronze"));
const BronzeFoundation = lazy(() => import("../components/BronzeIncentives/BronzeFoundation/BronzeFoundation"));
const BronzeBuilder = lazy(() => import("../components/BronzeIncentives/BronzeBuilder/BronzeBuilder"));
const BronzeIncentives = lazy(() => import("../components/BronzeIncentives/BronzeIncentives"));
const StrongStart = lazy(() => import("../components/StrongStart/StrongStart"));
const BronzeGroupTracking = lazy(() => import("../components/BronzeGroupTracking/BronzeGroupTracking"));
const PerformancePlusElite = lazy(() => import("../components/PerfPlusElite/PerfPlusElite"));
const MonthlyPGG = lazy(() => import("../components/PGG/MonthlyPGG/MonthlyPGG"));
const AnnualPGG = lazy(() => import("../components/PGG/AnnualPGG/AnnualPGG"));
const GcrPGG = lazy(() => import("../components/PGG/GCRPGG/GCRPGG"));
const FGG = lazy(() => import("../components/FrontlineGroupGrowth/FrontlineGroupGrowth"));
const TwoTimeCashIncentive = lazy(() => import("../components/TwoTimeCashIncentives/TwoTimeCashIncentive"));
const LeadershipSeminar = lazy(() => import("../components/LeadershipSeminar/LeadershipSeminar"));
const QCGrowth = lazy(() => import("../components/QCGrowth/QCGrowth"));
const VisionBonus = lazy(() => import("../components/VisionBonus/VisionBonus"));

export const Routing = () => {
  return (
    <Routes>
      <Route path="/" element={<App />}>
        {/* Performance Routes */}
        <Route path={ROUTE_PATHS.PERFORMANCE_DASHBOARD} element={<PerformanceDashboard />} />
        <Route path={ROUTE_PATHS.EARNINGS_CALCULATOR} element={<EarningsCalculator />} />
        {MBIRoutes()}
        {/* Early Incentives Routes */}
        <Route path={ROUTE_PATHS.CSI} element={<CSI />} />
        <Route path={ROUTE_PATHS.BRONZE_INCENTIVES} element={<BronzeIncentives />} />
        <Route path={ROUTE_PATHS.NEW_PATH_TO_BRONZE} element={<PathToBronze />} />
        <Route path={ROUTE_PATHS.NEW_BRONZE_FOUNDATION} element={<BronzeFoundation />} />
        <Route path={ROUTE_PATHS.NEW_BRONZE_BUILDER} element={<BronzeBuilder />} />
        <Route path={ROUTE_PATHS.STRONG_START_INCENTIVE} element={<StrongStart />} />
        {/* My Group Routes */}
        {CustomersRoutes()}
        {/* !!important!! DO NOT remove this redirect route, it is for markets that still using old link to re-route to new link */}
        <Route path={ROUTE_PATHS.CML} element={<Navigate to={ROUTE_PATHS.CUSTOMER_OVERVIEW} replace />} />
        <Route path={ROUTE_PATHS.LOS} element={<LOS />} />
        <Route path={ROUTE_PATHS.EDLOS} element={<EdLos />} />
        {ActionReportsRoutes()}
        <Route path={ROUTE_PATHS.WATCHLIST} element={<Watchlist />} />
        <Route path={ROUTE_PATHS.BRONZE_GROUP_TRACKING} element={<BronzeGroupTracking />} />
        {/* Leader Growth Routes */}
        <Route path={ROUTE_PATHS.PPE} element={<PerformancePlusElite />} />
        <Route path={ROUTE_PATHS.MONTHLY_PGG} element={<MonthlyPGG />} />
        <Route path={ROUTE_PATHS.ANNUAL_PGG} element={<AnnualPGG />} />
        <Route path={ROUTE_PATHS.PGG_GCR} element={<GcrPGG />} />
        <Route path={ROUTE_PATHS.FGG} element={<FGG />} />
        <Route path={ROUTE_PATHS.TWO_TIME_CASH_INCENTIVE} element={<TwoTimeCashIncentive />} />
        <Route path={ROUTE_PATHS.HAT} element={<HAT />} />
        <Route path={ROUTE_PATHS.FAA} element={<FAA />} />
        <Route path={ROUTE_PATHS.LEADERSHIP_SEMINAR} element={<LeadershipSeminar />} />
        <Route path={ROUTE_PATHS.QC_GROWTH} element={<QCGrowth />} />
        <Route path={ROUTE_PATHS.VISION_BONUS} element={<VisionBonus />} />
        {/* Resources Routes */}
        <Route path={ROUTE_PATHS.FEEDBACK} element={<ProvideUsFeedback />} />
        <Route path={ROUTE_PATHS.LOG_OUT} element={<LogOut />} />
        {AcademyRoute()}
        {/* Other */}
        {ErrorRoutes()}
        {AuthenticationRoutes()}
        <Route path="/" element={<HomePage />} />
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Routes>
  );
};
